import { t } from '@lib/i18n';
import { FeatureLayout } from '@widgets/layout';
import { createElement, Fragment } from 'react';
import { Link } from 'gatsby';
import { block } from 'bem-cn';
// hooks
import { useMediaQuery } from '@hooks/useMediaQuery';

// components
import Image from '@ui/Image';
import Typography from '@ui/Typography';

import './NotFoundPage.scss';

const cn = block('page-404');

const Container = ({ isTablet, children }) => createElement(
  isTablet ? 'div' : Fragment,
  null,
  children,
);

const TextContent = () => (
  <>
    <Typography variant="header2" className={cn('title')}>
      К сожалению, страница, которую вы&nbsp;ищете, не&nbsp;существует
    </Typography>
    <Typography className={cn('description')}>
      Но в нашем блоге много интересного.
      {' '}
      <Link href="https://waveservice.ru/blog/" className={cn('link')}>Подробнее</Link>
    </Typography>
  </>
);

const NotFoundPage = () => {
  const isTablet = useMediaQuery('(max-width: 687px)');
  const isMobile = useMediaQuery('(max-width: 480px)');
  return (
    <FeatureLayout
      mainLayoutProps={{ seo: t('seo.notFound') }}
      featureChildren={(
        <div className={cn('container')}>
          <Image src="/images/404-image.png" className={cn('image')} />
          <Container isTablet={isTablet && !isMobile}>
            <TextContent />
          </Container>
        </div>
      )}
      className="page-404__wrap"
    />
  );
};

export default NotFoundPage;
